import { Injectable } from '@angular/core';
import { SearchCriterionsDataType } from '../services/client-side-search.service';
import { Criterion } from '../services/base-event-service';

@Injectable({
  providedIn: 'root',
})
export class QuestionsSearchCriterionsFactory {
  constructor() {}
  protected readonly entities = {
    [QUESTIONS_SEARCH_CRITERION.NAME]: {
      id: QUESTIONS_SEARCH_CRITERION.NAME,
      title: 'Question Name',
      data: ['text'],
      type: SearchCriterionsDataType.String,
      controlName: 'questionName',
    },
    [QUESTIONS_SEARCH_CRITERION.TYPE]: {
      id: QUESTIONS_SEARCH_CRITERION.TYPE,
      title: 'Document Type',
      data: ['documentTypes'],
      type: SearchCriterionsDataType.ArrayNumber,
      controlName: 'documentType',
    },
    [QUESTIONS_SEARCH_CRITERION.POSITION]: {
      id: QUESTIONS_SEARCH_CRITERION.POSITION,
      title: 'My Position',
      data: ['positions'],
      type: SearchCriterionsDataType.ArrayNumber,
      controlName: 'position',
    },
    [QUESTIONS_SEARCH_CRITERION.LANGUAGE]: {
      id: QUESTIONS_SEARCH_CRITERION.LANGUAGE,
      title: 'Language',
      data: ['languageId'],
      type: SearchCriterionsDataType.Integer,
      controlName: 'languageId',
    },
  };

  getAllItemsAsArray() {
    return Object.values(this.entities) as Criterion[];
  }

  getById(id: number | QUESTIONS_SEARCH_CRITERION) {
    const item = { ...this.entities[id as QUESTIONS_SEARCH_CRITERION] };
    if (!item) {
      throw new Error(`Unknown id ${id} in ${this.constructor.name}`);
    }
    return item;
  }
}

export enum QUESTIONS_SEARCH_CRITERION {
  NAME = 1,
  TYPE = 2,
  POSITION = 3,
  LANGUAGE = 4,
}
