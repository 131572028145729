import { createReducer, on } from '@ngrx/store';
import { QuestionsActions } from './actions';
import { initialState } from './state';

export const featureReducer = createReducer(
  initialState,
  on(QuestionsActions.setQuestions, (state, { questions }) => ({
    ...state,
    questions: questions,
    loadingQuestions: false,
  })),
  on(QuestionsActions.loadQuestions, (state, {}) => ({
    ...state,
    loadingQuestions: true,
  })),
  on(QuestionsActions.loadQuestionsFail, (state, {}) => ({
    ...state,
    loadingQuestions: false,
  })),
  on(QuestionsActions.createQuestion, (state, {}) => ({
    ...state,
    creatingQuestion: true,
    closeCreatingQuestionModal: false,
  })),
  on(QuestionsActions.createQuestionCancel, (state, {}) => ({
    ...state,
    creatingQuestion: false,
    closeCreatingQuestionModal: false,
  })),
  on(QuestionsActions.createQuestionSuccess, (state, {}) => ({
    ...state,
    creatingQuestion: false,
    closeCreatingQuestionModal: false,
  })),
  on(QuestionsActions.createQuestionFail, (state, {}) => ({
    ...state,
    creatingQuestion: false,
    closeCreatingQuestionModal: false,
  })),
  on(QuestionsActions.createQuestionCancelled, (state, {}) => ({
    ...state,
    closeCreatingQuestionModal: true,
  })),
  on(QuestionsActions.closeCreatingQuestionModal, (state, {}) => ({
    ...state,
    closeCreatingQuestionModal: true,
  })),
  on(QuestionsActions.clearCreateQuestionCancelled, (state, {}) => ({
    ...state,
    closeCreatingQuestionModal: false,
  })),
  on(QuestionsActions.deleteQuestion, (state, {}) => ({
    ...state,
    deletingQuestion: true,
  })),
  on(QuestionsActions.deleteQuestionCancel, (state, {}) => ({
    ...state,
    deletingQuestion: false,
  })),
  on(QuestionsActions.deleteQuestionSuccess, (state, {}) => ({
    ...state,
    deletingQuestion: false,
  })),
  on(QuestionsActions.deleteQuestionFail, (state, {}) => ({
    ...state,
    deletingQuestion: false,
  })),
  on(QuestionsActions.setQuestion, (state, { question }) => ({
    ...state,
    question: question,
    loadingQuestion: false,
  })),
  on(QuestionsActions.loadQuestion, (state, {}) => ({
    ...state,
    loadingQuestion: true,
  })),
  on(QuestionsActions.loadQuestionFail, (state, {}) => ({
    ...state,
    loadingQuestion: false,
  })),
  on(QuestionsActions.editQuestion, (state, {}) => ({
    ...state,
    editingQuestion: true,
    closeEditingQuestionModal: false,
  })),
  on(QuestionsActions.editQuestionCancel, (state, {}) => ({
    ...state,
    editingQuestion: false,
    closeEditingQuestionModal: false,
  })),
  on(QuestionsActions.editQuestionSuccess, (state, {}) => ({
    ...state,
    editingQuestion: false,
    closeEditingQuestionModal: false,
  })),
  on(QuestionsActions.editQuestionFail, (state, {}) => ({
    ...state,
    editingQuestion: false,
    closeEditingQuestionModal: false,
  })),
  on(QuestionsActions.editQuestionCancelled, (state, {}) => ({
    ...state,
    question: null,
    closeEditingQuestionModal: true,
  })),
  on(QuestionsActions.closeEditingQuestionModal, (state, {}) => ({
    ...state,
    question: null,
    closeEditingQuestionModal: true,
  })),
  on(QuestionsActions.clearEditQuestionCancelled, (state, {}) => ({
    ...state,
    closeEditingQuestionModal: false,
  })),
);
