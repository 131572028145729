import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionsRoutingModule } from './questions-routing.module';

import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { QuestionsComponent } from './components/questions/questions.component';
import { QuestionsListComponent } from './components/questions-list/questions-list.component';
import { TranslocoModule } from '@ngneat/transloco';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { LetDirective } from 'src/app/core/utils/let-directive';
import { QuestionsStoreModule } from './store/module';
import { AddQuestionModalComponent } from './components/modals/add-question-modal/add-question-modal.component';
import { MatIconModule } from '@angular/material/icon';
import { EditQuestionModalComponent } from './components/modals/edit-question-modal/edit-question-modal.component';

@NgModule({
  declarations: [
    QuestionsComponent,
    QuestionsListComponent,
    AddQuestionModalComponent,
    EditQuestionModalComponent,
  ],
  imports: [
    QuestionsRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    CommonModule,
    TranslocoModule,
    PipesModule,
    QuestionsStoreModule,
    LetDirective,
    MatIconModule,
  ],
})
export class QuestionsModule {}
