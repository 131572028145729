<div *ngIf="data">
  <app-client-side-data-table
    [id]="'questions-table-id'"
    [data]="data"
    [columns]="columns"
    [sort]="sort"
    [eventService]="questionsListEventsService"
    [refreshIt]="refreshDataTable"
  ></app-client-side-data-table>
</div>
