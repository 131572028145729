import { SearchItemModel } from '../services/base-event-service';
import { FILTER_OPERATOR } from '../services/client-side-search.service';
import { QuestionsSearchModel } from 'src/app/modules/questions/models/questions.model';
import {
  QUESTIONS_SEARCH_CRITERION,
  QuestionsSearchCriterionsFactory,
} from '../factories/questions-search-criterions.factory';

export function getQuestionsFilterConditionsFromModel(
  model: QuestionsSearchModel,
  questionsSearchCriterionsFactory: QuestionsSearchCriterionsFactory,
): SearchItemModel[] {
  let conditions: SearchItemModel[] = [];

  if (!!model.questionName) {
    const criterion = questionsSearchCriterionsFactory.getById(QUESTIONS_SEARCH_CRITERION.NAME);
    const item = {
      criterion: criterion.id,
      operator: FILTER_OPERATOR.Contains,
      type: criterion.type,
      searchValue: model.questionName,
      controlName: criterion.controlName,
    } as SearchItemModel;

    conditions.push(item);
  }

  if (!!model.documentType) {
    const criterion = questionsSearchCriterionsFactory.getById(QUESTIONS_SEARCH_CRITERION.TYPE);
    const item = {
      criterion: criterion.id,
      operator: FILTER_OPERATOR.ContainsFromArray,
      type: criterion.type,
      searchValue: model.documentType.join(','),
      controlName: criterion.controlName,
    } as SearchItemModel;

    conditions.push(item);
  }

  if (!!model.position) {
    const criterion = questionsSearchCriterionsFactory.getById(QUESTIONS_SEARCH_CRITERION.POSITION);
    const item = {
      criterion: criterion.id,
      operator: FILTER_OPERATOR.ContainsFromArray,
      type: criterion.type,
      searchValue: model.position.join(','),
      controlName: criterion.controlName,
    } as SearchItemModel;

    conditions.push(item);
  }

  if (!!model.languageId) {
    const criterion = questionsSearchCriterionsFactory.getById(QUESTIONS_SEARCH_CRITERION.LANGUAGE);
    const item = {
      criterion: criterion.id,
      operator: FILTER_OPERATOR.ContainsFromArray,
      type: criterion.type,
      searchValue: model.languageId.join(','),
      controlName: criterion.controlName,
    } as SearchItemModel;

    conditions.push(item);
  }

  return conditions;
}
