import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { Observable } from 'rxjs';
import { GetQuestionDto, QuestionDto } from '../models/questions.model';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class QuestionsApiService extends ApiService {
  getQuestions$(): Observable<GetQuestionDto[]> {
    return this.get('/keyQuestions');
  }

  createQuestion$(question: QuestionDto): Observable<GetQuestionDto> {
    return this.put('/keyQuestions', question);
  }

  updateQuestion$(question: QuestionDto, uniqId: string): Observable<GetQuestionDto> {
    return this.post(`/keyQuestions/${uniqId}`, question);
  }

  getQuestionById$(uniqId: string): Observable<GetQuestionDto> {
    return this.get(`/keyQuestions/${uniqId}`);
  }

  deleteQuestion$(uniqueId: string): Observable<any> {
    return this.delete(`/keyQuestions/${uniqueId}`);
  }
}

export const apiKeyQuestionsProtectedResources = [
  {
    httpMethod: 'GET',
    scopes: [...environment.protectedResources.apiKeyQuestions.scopes.read],
  },
  {
    httpMethod: 'POST',
    scopes: [...environment.protectedResources.apiKeyQuestions.scopes.write],
  },
  {
    httpMethod: 'PUT',
    scopes: [...environment.protectedResources.apiKeyQuestions.scopes.write],
  },
  {
    httpMethod: 'DELETE',
    scopes: [...environment.protectedResources.apiKeyQuestions.scopes.write],
  },
];
