<div>
  @if (form) {
    <div [formGroup]="form">
      <div class="row-adaptive">
        <div class="col-adaptive">
          <app-search-input
            placeholder="{{ 'placeholders.filterByQuestionName' | transloco }}"
            formControlName="questionName"
            class="filter-control"
          ></app-search-input>
        </div>
        <div class="col-adaptive">
          <app-tui-multi-select-autocomplete
            formControlName="documentType"
            [autocompleteOptions]="docTypeItems"
            [placeholderKey]="'placeholders.filterByTypeOfDoc'"
          ></app-tui-multi-select-autocomplete>
        </div>
        <div class="col-adaptive">
          <app-tui-multi-select-autocomplete
            formControlName="position"
            [autocompleteOptions]="positionItems"
            [placeholderKey]="'placeholders.filterByMyPosition'"
          ></app-tui-multi-select-autocomplete>
        </div>
        <div class="col-adaptive">
          <app-tui-multi-select-autocomplete
            formControlName="languageId"
            [autocompleteOptions]="contentLanguages"
            optionLabel="displayName"
            [placeholderKey]="'placeholders.filterByLanguage'"
          ></app-tui-multi-select-autocomplete>
        </div>
        <div class="col-adaptive filter-btn-col">
          <app-button
            automation-id="create-btn"
            icon="filter_list_off"
            [type]="btnTypes.Icon_Stroked"
            [size]="btnSizes.InFilterRow"
            [title]="'buttons.clearFilters' | transloco"
            (onClick)="clearFilters()"
          ></app-button>
        </div>
        <div class="col-adaptive plus-btn-col">
          <app-button
            automation-id="create-btn"
            class="w100"
            icon="add"
            [type]="btnTypes.Icon"
            [size]="btnSizes.InFilterRow"
            [title]="'buttons.addQuestion' | transloco"
            (onClick)="addQuestion()"
          ></app-button>
        </div>
      </div>
    </div>
  }

  <app-questions-list class="app-questions-table"></app-questions-list>
</div>
