import { GetQuestionDto } from '../models/questions.model';

export interface QuestionsState {
  questions: GetQuestionDto[] | null;
  loadingQuestions: boolean;
  creatingQuestion: boolean;
  closeCreatingQuestionModal: boolean;
  deletingQuestion: boolean;
  question: GetQuestionDto | null;
  loadingQuestion: boolean;
  editingQuestion: boolean;
  closeEditingQuestionModal: boolean;
}

export const initialState: QuestionsState = {
  questions: null,
  loadingQuestions: false,
  creatingQuestion: false,
  closeCreatingQuestionModal: false,
  deletingQuestion: false,
  question: null,
  loadingQuestion: false,
  editingQuestion: false,
  closeEditingQuestionModal: false,
};
