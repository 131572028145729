import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QuestionsState } from './state';

export const QuestionsFeature = 'QuestionsFeature';

export const questionsFeatureStateSelector =
  createFeatureSelector<QuestionsState>(QuestionsFeature);

export const selectQuestions = createSelector(
  questionsFeatureStateSelector,
  (state: QuestionsState) => state.questions,
);

export const selectQuestionsLoading = createSelector(
  questionsFeatureStateSelector,
  (state: QuestionsState) => state.loadingQuestions,
);

export const selectQuestionCreating = createSelector(
  questionsFeatureStateSelector,
  (state: QuestionsState) => state.creatingQuestion,
);

export const selectCloseCreatingQuestionModal = createSelector(
  questionsFeatureStateSelector,
  (state: QuestionsState) => state.closeCreatingQuestionModal,
);

export const selectQuestionLoading = createSelector(
  questionsFeatureStateSelector,
  (state: QuestionsState) => state.loadingQuestion,
);

export const selectQuestion = createSelector(
  questionsFeatureStateSelector,
  (state: QuestionsState) => state.question,
);

export const selectQuestionEditing = createSelector(
  questionsFeatureStateSelector,
  (state: QuestionsState) => state.editingQuestion,
);

export const selectCloseEditingQuestionModal = createSelector(
  questionsFeatureStateSelector,
  (state: QuestionsState) => state.closeEditingQuestionModal,
);

export const fromQuestions = {
  selectQuestions,
  selectQuestionsLoading,
  selectCloseCreatingQuestionModal,
  selectQuestionCreating,
  selectQuestion,
  selectQuestionLoading,
  selectQuestionEditing,
  selectCloseEditingQuestionModal,
};
