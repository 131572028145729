import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject, catchError, combineLatest, filter, of } from 'rxjs';
import { constants } from 'src/app/core/constants/constants';
import { AlertType } from 'src/app/shared/components/app-alert/app-alert.component';
import { DocumentTypesApiService } from 'src/app/modules/doc-types/services/document-types-api.service';
import { PositionDto, PositionsApiService } from 'src/app/shared/services/positions-api.service';
import { AppButtonSize, AppButtonType } from 'src/app/shared/shared.model';
import { UserDto } from 'src/app/modules/login/services/user-api.service';
import { selectDefaultContentLanguageId, selectUser } from 'src/app/core/store/selectors';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/state';
import { isPresent } from 'src/app/core/utils/isPresent';
import { fromQuestions } from '../../../store/selectors';
import { QuestionsActions } from '../../../store/actions';
import { QuestionDto } from '../../../models/questions.model';
import { GetDocumentTypeDto } from 'src/app/modules/doc-types/models/doc-types.model';
import { LanguageDto } from 'src/app/shared/services/languages-api.service';
import { selectContentLanguages } from 'src/app/shared/store/selectors';

@UntilDestroy()
@Component({
  selector: 'app-question-modal',
  templateUrl: './add-question-modal.component.html',
  styleUrl: './add-question-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddQuestionModalComponent implements OnInit, AfterViewInit {
  public onClose: Subject<boolean> = new Subject<boolean>();
  public form: FormGroup | undefined;
  public addDocTypeForm: FormGroup | undefined;
  public body: string | undefined;
  public title: string = this.translocoService.translate(`titles.addQuestion`);
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;
  public alertTypes = AlertType;
  public constants = constants;

  public docTypeItems: any;
  public docStatusItems: any;
  public positionItems!: PositionDto[];
  public conterPartyItems: any;
  public hiddenConterPartyItems: any;

  public creatingNewDocType: boolean = false;

  public user$: Observable<UserDto | null> | undefined;
  public currentUser: UserDto | null | undefined;
  public isQuestionCreating$ = this.store$.select(fromQuestions.selectQuestionCreating);
  public contentLanguages: LanguageDto[] = [];

  constructor(
    private store$: Store<AppState>,
    private bsModalRef: BsModalRef,
    private elementRef: ElementRef,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly documentTypesApiService: DocumentTypesApiService,
    private readonly positionsApiService: PositionsApiService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngAfterViewInit(): void {
    this.store$
      .select(fromQuestions.selectCloseCreatingQuestionModal)
      .pipe(untilDestroyed(this))
      .subscribe((close) => {
        if (close) {
          this.bsModalRef.hide();
          this.store$.dispatch(QuestionsActions.clearCreateQuestionCancelled());
        }
      });
  }

  public ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    combineLatest([
      this.store$.select(selectUser),
      this.store$.select(selectDefaultContentLanguageId),
      this.store$.select(selectContentLanguages).pipe(filter(isPresent)),
      this.documentTypesApiService.getDocumentTypes$(),
      this.positionsApiService.getPositions$(),
    ])
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe(([user, defaultContentLanguageId, contentLanguages, docTypes, postitions]) => {
        this.currentUser = user;
        this.contentLanguages = contentLanguages;
        this.docTypeItems = docTypes;
        this.positionItems = postitions;

        setTimeout(() => {
          this.form = this.fb.group({
            questionText: ['', { validators: [Validators.required] }],
            documentTypes: [[], { validators: [Validators.required] }],
            positions: [[], { validators: [Validators.required] }],
            languageId: [defaultContentLanguageId, { validators: [Validators.required] }],
          });

          this.addDocTypeForm = this.fb.group({
            newDocumentType: ['', { validators: [Validators.required] }],
          });

          this.cdr.markForCheck();
        });
      });
  }

  openNewDocTypeForm() {
    this.creatingNewDocType = true;
  }

  onConfirmNewDocType() {
    this.documentTypesApiService
      .createDocumentType$(this.addDocTypeForm?.get('newDocumentType')?.value)
      .pipe(
        untilDestroyed(this),
        catchError(() => {
          this.addDocTypeForm?.get('newDocumentType')?.setErrors({ docTypeNameAlreadyExist: true });
          const invalidControl = this.elementRef.nativeElement.querySelector('#newDocumentType');
          invalidControl?.focus();
          return of();
        }),
      )
      .subscribe((resp: GetDocumentTypeDto) => {
        this.form?.get('documentTypes')?.patchValue([resp.id]);
        this.form?.updateValueAndValidity();
        this.onCancelNewDocType();
      });
  }

  onCancelNewDocType() {
    this.addDocTypeForm?.reset();
    this.creatingNewDocType = false;
    this.cdr.markForCheck();
  }

  public onConfirm(): void {
    const text = this.form?.get('questionText')?.value;
    const documentTypes = this.form
      ?.get('documentTypes')
      ?.value?.split(',')
      .map((id: string) => +id);
    const positions = this.form
      ?.get('positions')
      ?.value?.split(',')
      .map((id: string) => +id);
      const languageId = this.form?.get('languageId')?.value;

    const model = {
      text,
      documentTypes,
      positions,
      languageId
    } as QuestionDto;

    this.store$.dispatch(QuestionsActions.createQuestion({ model }));
  }

  public onCancel(): void {
    const pristine = !!this.form?.pristine;
    this.store$.dispatch(QuestionsActions.createQuestionCancel({ pristine }));
  }
}
